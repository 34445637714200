
import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import axios from "axios";

let zIndex : number = 1000;

export function OasisDecodeError(error: unknown): string {
    if (!error) {
        return "Unknown error";
    }

    if (typeof error === 'string')
        return error;

    if (!axios.isAxiosError(error)) {
        if (error instanceof Error) {
            return error.message;
        }        
        return "Unknown error";
    }

    if (error.response && [401, 500].includes(error.response.status)) {
        const responseData = error.response.data;

        if (typeof responseData === "string") {
            return responseData;
        }

        if (typeof responseData === "object" && responseData !== null && "message" in responseData) {
            const message = responseData["message"];
            if (typeof message === "string") {
                return message;
            }
        }
    }

    return error.message;
}


export function ShowModalError(title: string, error: unknown) {
    const msg = OasisDecodeError(error);
    modals.open({
        title: title,
        centered: true,
        onClose: () => {
            zIndex -= 10;
        },
        zIndex: zIndex,
        children: (
            <Text mt={"xs"}>
                    {msg}
            </Text>
        )        
    });
    zIndex += 10;
}