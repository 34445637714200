import {
    ArrowType,
    ChildItem,
    ChildrenInfo,
    DEFAULT_COLLAPSED,
    LineInfo,
    LineItem,
} from "./UniversalMapData";

export function toggleItemById(id: string, items: ChildItem[]): ChildItem[] {
    let changed = false;

    const updatedItems = items.map((item) => {
        if (item.id === id) {
            changed = true;
            return {
                ...item,
                childrenCollapsed: !(
                    item.childrenCollapsed ?? DEFAULT_COLLAPSED
                ),
            };
        }

        if (item.children) {
            const updatedChildren = toggleItemById(id, item.children);
            if (updatedChildren !== item.children) {
                changed = true;
                return {
                    ...item,
                    children: updatedChildren,
                };
            }
        }

        return item;
    });

    return changed ? updatedItems : items;
}

// Folded line collapsing

type FoldedLineItem = {
    id: string;
    type?: string;
    arrow?: ArrowType;
    src: string;
    dst: string;
    actualLines: LineItem[];
};

function joinTooltips(foldedLineItem: FoldedLineItem, tooltipField: 'srcTooltip' | 'dstTooltip'): string | undefined {
    const uniqueSortedTooltips = [...new Set(
        foldedLineItem.actualLines
            .map(line => line[tooltipField])
            .filter(Boolean)
            .flatMap(tip => tip ? tip.split('\n').map(line => line.trim()) : [])
    )].sort().join('\n');

    return uniqueSortedTooltips || undefined;
}


export function FoldLinesForUnvisibleItems(args: {
    origLines: LineInfo;
    actualScheme: ChildrenInfo;
}): LineInfo {
    const { origLines, actualScheme } = args;
    let result: LineInfo = { items: [] };

    // Key is id of item, value is first visible parent (itself if element is visible)
    let visibilityOfItems: { [key: string]: ChildItem } = {};

    function walkChildrens(
        children: ChildItem[],
        visible: boolean,
        visibleParent: ChildItem
    ) {
        children.forEach((c) => {
            visibilityOfItems[c.id] = visible ? c : visibleParent;
            if (c.children) {
                walkChildrens(
                    c.children,
                    visible && !(c.childrenCollapsed ?? DEFAULT_COLLAPSED),
                    visible ? c : visibleParent
                );
            }
        });
    }
    if (actualScheme.children) {
        walkChildrens(actualScheme.children, true, { id: "fakeRoot" });
    }    

    // List to temprorary hold folded lines
    let foldedLines: FoldedLineItem[] = [];
    origLines.items.forEach((line) => {
        const srcVisibility = visibilityOfItems[line.src];
        const dstVisibility = visibilityOfItems[line.dst];
        const srcVisible = line.src === srcVisibility.id;
        const dstVisible = line.dst === dstVisibility.id;
        if (srcVisible && dstVisible) {
            // All visible, just add to result
            result.items.push(line);
        } else {
            if (srcVisibility !== dstVisibility) {
                // Not "internal" line
                const foundFoldedLine = foldedLines.find(
                    (l) =>
                        l.src === srcVisibility.id &&
                        l.dst === dstVisibility.id &&
                        l.type === line.type &&
                        l.arrow === line.arrow
                );
                if (foundFoldedLine) {
                    foundFoldedLine.actualLines.push(line);
                } else {
                    foldedLines.push({
                        id: `gen_${srcVisibility.id}_${dstVisibility.id}_${
                            line.type ?? ""
                        }_${line.arrow ?? ""}`,
                        src: srcVisibility.id,
                        dst: dstVisibility.id,
                        type: line.type,
                        arrow: line.arrow,
                        actualLines: [line],
                    });
                }
            }
        }
    });

    foldedLines.forEach((l) => {
        result.items.push({
            id: l.id,
            src: l.src,
            dst: l.dst,
            type: l.type,
            arrow: l.arrow,
            srcTooltip: joinTooltips(l, "srcTooltip"),
            dstTooltip: joinTooltips(l, "dstTooltip")
        });
    });

    return result;
}
