import { useCallback, useEffect, useRef, useState } from "react";
import { api } from "../api";
import { OasisDecodeError } from "../oasiserror";
import {
    IAIStack,
    IAIStackChangeset,
    IAIStackCompareDiff,
    IAIStackIssue,
    IAIStackIssueContent,
} from "../models/IAIAnalysis";

export function useAIAnalysis() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<IAIStack[] | undefined>(undefined);

    const latestRequestIdRef = useRef<number>(0);

    async function fetch(silent?: boolean) {
        const currentRequestId = ++latestRequestIdRef.current;

        if (!silent) setLoading(true);
        setError(null);

        try {
            const response = await api.get<IAIStack[]>("/api/ai/stacks");
            if (currentRequestId === latestRequestIdRef.current) {
                setData(response.data);
            }
        } catch (e: unknown) {
            if (currentRequestId === latestRequestIdRef.current) {
                setError(OasisDecodeError(e));
            }
        } finally {
            if (!silent && currentRequestId === latestRequestIdRef.current) 
                setLoading(false);
        }
    }

    async function analyze(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/analyze`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function wontfix(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/wontfix`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function fix(id: number, sel: number[]) {
        try {
            await api.post(`/api/ai/stacks/${id}/fix`, JSON.stringify(sel), {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function fixanotherissues(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/fixanotherissues`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function totemplateedit(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/totemplateedit`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function reset(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/reset`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
    }

    async function apply(id: number) {
        try {
            await api.post(`/api/ai/stacks/${id}/apply`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }   
    }
    
    async function analyzeAll() {        
        try {
            await api.post(`/api/ai/stacks/analyzeall`);
        } catch (e: unknown) {            
            throw e;
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    return {
        loading,
        error,
        data,
        fetch,
        analyze,
        wontfix,
        fix,
        fixanotherissues,
        totemplateedit,
        reset,
        apply,
        analyzeAll
    };
}

export function useAIAnalysisIssues(stack: IAIStack) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<IAIStackIssue[]>([]);

    const latestRequestIdRef = useRef<number>(0);

    const fetch = useCallback(async () => {
        const currentRequestId = ++latestRequestIdRef.current;

        setLoading(true);
        setError(null);

        try {
            const response = await api.get<IAIStackIssueContent>(
                `/api/ai/stacks/${stack.id}/issues`
            );
            if (currentRequestId === latestRequestIdRef.current) {
                setData(response.data.Issues);
                setLoading(false);
            }
        } catch (e: unknown) {
            if (currentRequestId === latestRequestIdRef.current) {
                setError(OasisDecodeError(e));
                setLoading(false);
            }
        }
    }, [stack.id]);

    useEffect(() => {
        fetch();
    }, [stack.id, fetch]);

    return { loading, error, data, fetch };
}

export function useAIAnalysisChangeset(stack: IAIStack) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<IAIStackChangeset>();

    const latestRequestIdRef = useRef<number>(0);

    const fetch = useCallback(async () => {
        const currentRequestId = ++latestRequestIdRef.current;

        setLoading(true);
        setError(null);

        try {
            const response = await api.get<IAIStackChangeset>(
                `/api/ai/stacks/${stack.id}/changeset`
            );
            if (currentRequestId === latestRequestIdRef.current) {
                setData(response.data);
                setLoading(false);
            }
        } catch (e: unknown) {
            if (currentRequestId === latestRequestIdRef.current) {
                setError(OasisDecodeError(e));
                setLoading(false);
            }
        }
    }, [stack.id]);

    useEffect(() => {
        fetch();
    }, [stack, fetch]);

    return { loading, error, data, fetch };
}

export function useAIAnalysisCompare(stack_id: number) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [data, setData] = useState<IAIStackCompareDiff | undefined>(
        undefined
    );

    const fetch = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get<IAIStackCompareDiff>(
                `/api/ai/stacks/${stack_id}/compare`
            );
            setData(response.data);
        } catch (e: unknown) {
            setError(OasisDecodeError(e));
        }

        setLoading(false);
    }, [stack_id]);

    const createchangeset = useCallback(async () => {
        setLoading(true);
        try {
            await api.post(`/api/ai/stacks/${stack_id}/createchangeset`);
        } catch (e: unknown) {
            setLoading(false);
            throw e;
        }
        fetch(); // refresh state
        setLoading(false);
    }, [stack_id, fetch]);

    const edit = useCallback(
        async (template: string) => {
            setLoading(true);
            try {
                await api.post(`/api/ai/stacks/${stack_id}/edit`, template, {
                    headers: {
                        "Content-type": "text/plain; charset=utf-8",
                    },
                });
            } catch (e: unknown) {
                setLoading(false);
                throw e;
            }
            fetch(); // refresh state
            setLoading(false);
        },
        [stack_id, fetch]
    );

    useEffect(() => {
        fetch();
    }, [stack_id, fetch]);

    return { loading, error, data, fetch, createchangeset, edit };
}
