import React, { useState } from "react";

import { Anchor, Button, Group, Loader, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCloudMinus, IconDatabase, IconEdit } from "@tabler/icons-react";

import {
    CloudType,
    ICloudTest,
    ICloudView,
    SyncState,
} from "../core/models/ICloud";
import Moment from "react-moment";

interface CloudTableRowProps {
    cloud: ICloudView;
    edit?: (cloud: ICloudView) => void;
    makeTest?: (name: string, cloud: ICloudTest) => Promise<void>;
    makeSync?: (cloud: ICloudView) => Promise<void>;
    makeDelete?: (cloud: ICloudView) => Promise<void>;
    makeRefresh?: (silent?: boolean) => Promise<void>;
    moreInfoShowed: boolean;
}

export function CloudTableRow({
    cloud,
    edit,
    makeTest,
    makeSync,
    makeDelete,
    makeRefresh,
    moreInfoShowed,
}: CloudTableRowProps) {
    const [syncLoading, setSyncLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [testLoading, setTestLoading] = useState(false);

    const onEditInternal = () => {
        if (edit) {
            edit(cloud);
        }
    };

    const onSyncInternal = async () => {
        if (makeSync) {
            setSyncLoading(true);
            await makeSync(cloud);
            if (makeRefresh) {
                await makeRefresh(true);
            }
            setSyncLoading(false);
        }
    };

    const onDeleteInternal = async () => {
        if (makeDelete) {
            modals.openConfirmModal({
                title: "Delete your cloud",
                centered: true,
                children: (
                    <Text size="sm">
                        Are you sure you want to delete your cloud?
                    </Text>
                ),
                labels: {
                    confirm: "Delete cloud",
                    cancel: "No, don't delete it",
                },
                confirmProps: { color: "red" },
                onConfirm: async () => {
                    setDeleteLoading(true);
                    await makeDelete(cloud);
                    setDeleteLoading(false);
                },
            });
        }
    };

    const onTestInternal = async () => {
        if (makeTest && !testLoading) {
            setTestLoading(true);
            const data: ICloudTest = {
                id: cloud.id,
            };
            await makeTest(cloud.name, data);
            setTestLoading(false);
        }
    };

    return (
        <tr key={cloud.id}>
            <td>{cloud.name}</td>
            {moreInfoShowed && (
                <td>
                    <b>Cloud type:</b> {cloud.cloud_type} <br />
                    {cloud.cloud_type === CloudType.AWS && (
                        <>
                            <b>Region:</b> {cloud.aws_region} <br />
                            <b>Key:</b> {cloud.aws_key}
                        </>
                    )}
                    {cloud.cloud_type === CloudType.AZURE && (
                        <>
                            <b>Subscription ID:</b>{" "}
                            {cloud.azure_subscription_id}
                            <br />
                            <b>Tenant ID:</b> {cloud.azure_tenant_id}
                            <br />
                            <b>Client ID:</b> {cloud.azure_client_id}
                        </>
                    )}
                    {cloud.cloud_type === CloudType.K8S && (
                        <>
                            <Text color="dimmed">Config saved</Text>
                        </>
                    )}
                    {cloud.cloud_type === CloudType.HUAWEI && (
                        <>
                            <b>Region:</b> {cloud.huawei_region}
                            <br />
                            <b>Access Key:</b> {cloud.huawei_access_key}
                            {cloud.huawei_project_id && (
                                <>
                                    <br />
                                    <b>Project ID:</b> {cloud.huawei_project_id}
                                </>
                            )}
                        </>
                    )}
                    {cloud.cloud_type === CloudType.GOOGLE && (
                        <>
                            <Text color="dimmed">Config saved</Text>
                        </>
                    )}
                    {cloud.cloud_type === CloudType.DIGITALOCEAN && (
                        <>
                            <Text color="dimmed">
                                Personal Access Token saved
                            </Text>
                            <b>Spaces Access Key:</b>{" "}
                            {cloud.digitalocean_access_key}
                        </>
                    )}
                </td>
            )}
            <td>
                {cloud.sync_state === SyncState.InSync ? (
                    <Group spacing="xs">
                        <Loader ml="md" />
                        <Text>
                            Started{" "}
                            {cloud.sync_start && (
                                <Moment fromNow>{cloud.sync_start}</Moment>
                            )}
                        </Text>
                    </Group>
                ) : (
                    <>
                        {cloud.sync_msg ? (
                            <>
                                <Text color="red">
                                    <b>Error:</b> {cloud.sync_msg}
                                </Text>
                                {cloud.sync_stop && (
                                    <Text>
                                        <Moment fromNow>
                                            {cloud.sync_stop}
                                        </Moment>
                                    </Text>
                                )}
                                {makeTest && (
                                    <Anchor
                                        type="button"
                                        onClick={onTestInternal}
                                    >
                                        Click to test the connection{" "}
                                        {testLoading && <Loader size="14" />}
                                    </Anchor>
                                )}
                                {cloud.last_successful_sync && (
                                    <Text>
                                        Last successful sync was{" "}
                                        <Moment fromNow>
                                            {cloud.last_successful_sync}
                                        </Moment>
                                    </Text>
                                )}
                            </>
                        ) : (
                            <>
                                {cloud.sync_stop && (
                                    <Text>
                                        <Moment fromNow>
                                            {cloud.sync_stop}
                                        </Moment>
                                    </Text>
                                )}
                            </>
                        )}
                    </>
                )}
            </td>
            <td>
                <Group spacing="xs" position="center">
                    {makeSync && (
                        <Button
                            leftIcon={<IconDatabase size="1.125rem" />}
                            color="green"
                            loading={syncLoading || cloud.sync_state === SyncState.InSync}
                            disabled={deleteLoading}
                            onClick={onSyncInternal}
                        >
                            Sync
                        </Button>
                    )}
                    {edit && moreInfoShowed && (
                        <Button
                            leftIcon={<IconEdit size="1.125rem" />}
                            color="blue"
                            disabled={deleteLoading || syncLoading}
                            onClick={onEditInternal}
                        >
                            Edit
                        </Button>
                    )}
                    {makeDelete && moreInfoShowed && (
                        <Button
                            leftIcon={<IconCloudMinus size="1.125rem" />}
                            color="red"
                            loading={deleteLoading}
                            disabled={syncLoading}
                            onClick={onDeleteInternal}
                        >
                            Delete
                        </Button>
                    )}
                </Group>
            </td>
        </tr>
    );
}
