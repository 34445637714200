import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    Button,
    CopyButton,
    Group,
    LoadingOverlay,
    SegmentedControl,
    Text,
    Tooltip,
    useMantineTheme,
} from "@mantine/core";
import {
    IconAlertTriangle,
    IconArrowBack,
    IconMoodCheck,
} from "@tabler/icons-react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { useAIAnalysisCompare } from "../core/hooks/aianalysis";
import { useMediaQuery } from "@mantine/hooks";
import { StackAnalyzeState } from "../core/models/IAIAnalysis";
import { ShowModalError } from "../core/oasiserror";
import { modals } from "@mantine/modals";

function UploadTemplateButton({
    saveTemplate,
}: {
    saveTemplate: (newTemplate: string) => void;
}) {
    const [dragActive, setDragActive] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileRead = (file: File) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const content = e.target!.result as string;
            setInputKey(Date.now());
            saveTemplate(content);
        };

        reader.readAsText(file);
    };

    const handleDrag = function (e: React.DragEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileRead(e.dataTransfer.files[0]);
        }
    };

    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileRead(e.target.files[0]);
        }
    };

    const openFileDialog = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <form
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
            onSubmit={(e) => e.preventDefault()}
        >
            <input
                key={inputKey}
                ref={inputRef}
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
            />
            <label
                htmlFor="file-upload"
                className={dragActive ? "drag-active" : ""}
            >
                <Tooltip
                    multiline
                    width={220}
                    label={
                        <>
                            You can edit the AI-generated template in your
                            favourite text editor and replace it before applying
                            the changes.
                            <br />
                            You can simply drag file over this button to upload
                            new template in UTF-8 encoding.
                        </>
                    }
                >
                    <Button
                        onClick={openFileDialog}
                        color={dragActive ? "teal" : "blue"}
                    >
                        Upload template
                    </Button>
                </Tooltip>
            </label>
        </form>
    );
}

function AICompareTemplates() {
    const theme = useMantineTheme();
    const isDark = theme.colorScheme === "dark";
    const matches = useMediaQuery("(min-width: 70em)");
    const navigate = useNavigate();

    let { id } = useParams();
    const { loading, error, data, createchangeset, edit } =
        useAIAnalysisCompare(Number(id));
    const [view, setView] = useState<string>("unified");

    useEffect(() => {
        setView(matches ? "split" : "unified");
    }, [matches]);

    const saveTemplate = useCallback(
        async (newTemplate: string) => {
            try {
                await edit(newTemplate);
            } catch (e: unknown) {
                ShowModalError(
                    `Cannot edit new template to deployment "${data?.name}"`,
                    e
                );
            }
        },
        [data, edit]
    );

    const revertHumanTemplate = async () => {
        try {
            if (data?.ai_template) {
                await edit(data?.ai_template);
            }
        } catch (e: unknown) {
            ShowModalError(`Cannot revert user template to AI template"`, e);
        }
    };

    const makeCreatechangeset = async () => {
        modals.openConfirmModal({
            title: "Create a deployment changeset",
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to create a deployment changeset in
                    your cloud?
                </Text>
            ),
            labels: {
                confirm: "Create changeset",
                cancel: "No, don't do it",
            },
            confirmProps: { color: "green" },
            onConfirm: async () => {
                try {
                    await createchangeset();
                    navigate(`/ai/stack/${id}`);
                } catch (e: unknown) {
                    ShowModalError(`Cannot create a deployment changeset`, e);
                }
            },
        });
    };

    if (!loading && error) {
        return (
            <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Unable to obtain information for deployment template comparison"
                color="red"
                mt={"xs"}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {error}
            </Alert>
        );
    }

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Group m="xs">
                <Link to={!isNaN(Number(id)) ? `/ai/stack/${id}` : "/ai"}>
                    <Button color="blue" leftIcon={<IconArrowBack />}>
                        Back
                    </Button>
                </Link>
                <Text c="dimmed">{data?.cloud_name}</Text>—
                <Text>{data?.name}</Text>
            </Group>
            {data?.state !== StackAnalyzeState.FixReady ? (
                <Text m="xs">
                    {/* if undefined/null do not show anything */}
                    {data?.state !== undefined
                        ? "The deployment is not in the FixReady analysis state."
                        : ""}
                </Text>
            ) : (
                <>
                    <Group m="xs">
                        <Text>
                            <Tooltip label="Fix ready">
                                <IconMoodCheck
                                    color="orange"
                                    style={{ marginRight: "0.5rem" }}
                                />
                            </Tooltip>
                            Compare the changes made by AI to the deployment
                            template, then create a changeset for the deployment
                            in your cloud.
                            {data?.human_template !== data?.ai_template ? (
                                <>
                                    {
                                        " AI template was changed by user, to revert changes press button "
                                    }
                                    <Button
                                        size="compact-md"
                                        color="blue"
                                        onClick={revertHumanTemplate}
                                    >
                                        Revert
                                    </Button>
                                </>
                            ) : (
                                ""
                            )}
                        </Text>
                    </Group>
                    <Group ml="xs" mr="xs">
                        <SegmentedControl
                            value={view}
                            onChange={setView}
                            data={[
                                { label: "Split", value: "split" },
                                { label: "Unified", value: "unified" },
                            ]}
                        />
                        <Button color="green" onClick={makeCreatechangeset}>
                            Create changeset
                        </Button>
                        {data?.human_template ? (
                            <CopyButton value={data?.human_template}>
                                {({ copied, copy }) => (
                                    <Tooltip label="Copy fixed template to clipboard">
                                        <Button
                                            color={copied ? "teal" : "blue"}
                                            onClick={copy}
                                        >
                                            {copied
                                                ? "Fixed template copied in clipboard"
                                                : "Copy fixed template"}
                                        </Button>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        ) : null}
                        <UploadTemplateButton saveTemplate={saveTemplate} />
                    </Group>
                    <ReactDiffViewer
                        leftTitle={
                            view === "split"
                                ? "Current template"
                                : "Changes in fixed template"
                        }
                        rightTitle={
                            view === "split" ? "Fixed template" : undefined
                        }
                        oldValue={data?.current_template ?? undefined}
                        newValue={data?.human_template ?? undefined}
                        splitView={view === "split"}
                        useDarkTheme={isDark}
                        compareMethod={DiffMethod.WORDS}
                        
                    />
                </>
            )}
        </>
    );
}

export default AICompareTemplates;
