import { useEffect, useRef, useState } from "react";
import { ICloudAdd, ICloudTest, ICloudTestResult, ICloudView, SyncState } from "../models/ICloud";
import { api } from "../api";
import { OasisDecodeError } from "../oasiserror";

export function useClouds() {
    const [loading, setLoading] = useState(true);    
    const [error, setError] = useState<null | string>(null);
    const [clouds, setClouds] = useState<ICloudView[]>([]);
    
    const latestRequestIdRef = useRef<number>(0);

    async function fetchClouds(silent?: boolean) {
        const currentRequestId = ++latestRequestIdRef.current;

        if (!silent) setLoading(true);
        setError(null);

        try {
            const response = await api.get<ICloudView[]>("/api/clouds");
            if (currentRequestId === latestRequestIdRef.current) {
                setClouds(response.data);
            }
        } catch (e: unknown) {
            if (currentRequestId === latestRequestIdRef.current) {
                setError(OasisDecodeError(e));
            }
        } finally {
            if (!silent && currentRequestId === latestRequestIdRef.current) {
                setLoading(false);
            }
        }
    }

    async function syncCloud(id: number) {
        await api.post(`/api/cloud/${id}/sync`);
    };

    async function deleteCloud(id: number) {
        await api.delete(`/api/cloud/${id}`);
        setClouds(clouds.filter((cloud) => cloud.id !== id));
    }

    async function addCloud(cloud: ICloudAdd) {
        const response = await api.post<ICloudView>('/api/cloud', JSON.stringify(cloud), {
            headers: {
                'Content-Type': 'application/json'
                }
        });
        setClouds([ response.data, ...clouds ]);
    }

    async function editCloud(id: number, cloud: ICloudAdd) {
        await api.post<ICloudView>(`/api/cloud/${id}`, JSON.stringify(cloud), {
            headers: {
                'Content-Type': 'application/json'
                }
        });
        fetchClouds();
    }

    async function testCloud(cloud: ICloudTest) : Promise<ICloudTestResult> {
        const response = await api.post<ICloudTestResult>(`/api/cloud/test`, JSON.stringify(cloud), {
            headers: {
                'Content-Type': 'application/json'
                }
        });
        return response.data;
    };

    useEffect(() => {
        fetchClouds();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (clouds.some(cloud => cloud.sync_state === SyncState.InSync)) {
                fetchClouds(true);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [clouds]);

    return { loading, error, clouds, fetchClouds, syncCloud, deleteCloud, addCloud, editCloud, testCloud };
}
