import { Paper } from "@mantine/core";
import {
    LineInfo,
    LineStyle,
    LineStyles,
    ArrowType,
    TypedLineStyle,
    mergeObjects,
} from "./UniversalMapData";
import { Fragment, useLayoutEffect, useRef, useState } from "react";

const DEFAULT_GRAVITY = 75;
const DEFAULT_STROKE = "cornflowerblue";
const DEFAULT_STROKE_WIDTH = 4;
const DEFAULT_STROKE_OPACITY = 0.6;

type Position = {
    x: number;
    y: number;
    width: number;
    height: number;
};

type ElementInfo = {
    id: string;
    elem: HTMLElement;
    pos: Position;
    center: {
        x: number;
        y: number;
    };
};

type ExtentedInfo = {
    id: string;
    type?: string;
    arrow?: ArrowType;
    src: ElementInfo;
    dst: ElementInfo;
    dist2: number;
    srcDstAngle: number;
    srcAngle: number;
    dstAngle: number;
    srcTooltip?: string;
    dstTooltip?: string;
};

type ArrowInfo = {
    type: "src" | "dst";
    angle: number;
    extInfo: ExtentedInfo;
};

function getOffsetRect(
    element: HTMLElement,
    parent: HTMLElement
): Position | null {
    var el: HTMLElement = element,
        offsetLeft = 0,
        offsetTop = 0;

    do {
        offsetLeft += el.offsetLeft;
        offsetTop += el.offsetTop;

        if (!el.offsetParent || !(el.offsetParent as HTMLElement)) {
            return null;
        }
        el = el.offsetParent as HTMLElement;
    } while (el !== parent);

    return {
        x: offsetLeft,
        y: offsetTop,
        width: element.offsetWidth,
        height: element.offsetHeight,
    };
}

function calculateDist2Angle(
    c1: { x: number; y: number },
    c2: { x: number; y: number }
): number[] {
    const deltaX = c2.x - c1.x;
    const deltaY = c2.y - c1.y;
    let radians = Math.atan2(deltaY, deltaX);
    if (radians < 0) {
        radians += 2 * Math.PI;
    }
    const dist2 = deltaX * deltaX + deltaY * deltaY;
    return [dist2, radians];
}

function findIntersectionOffset(
    alpha: number,
    width: number,
    height: number
): {
    x: number;
    y: number;
} {
    var rayDirX = Math.cos(alpha);
    var rayDirY = Math.sin(alpha);

    var x1 = -width / 2,
        y1 = -height / 2;
    var x2 = width / 2,
        y2 = height / 2;

    var intersection = null;

    // top or bottom
    if (rayDirY < 0) {
        intersection = { x: (rayDirX / rayDirY) * y1 + x2, y: 0 };
        if (intersection.x >= 0 && intersection.x <= width) {
            return intersection;
        }
    } else if (rayDirY > 0) {
        intersection = { x: (rayDirX / rayDirY) * y2 + x2, y: height };
        if (intersection.x >= 0 && intersection.x <= width) {
            return intersection;
        }
    }

    // left or right
    if (rayDirX < 0) {
        intersection = { x: 0, y: (rayDirY / rayDirX) * x1 + y2 };
        if (intersection.y >= 0 && intersection.y <= height) {
            return intersection;
        }
    } else if (rayDirX > 0) {
        intersection = { x: width, y: (rayDirY / rayDirX) * x2 + y2 };
        if (intersection.y >= 0 && intersection.y <= height) {
            return intersection;
        }
    }

    // unreach
    return { x: 0, y: 0 };
}

interface UniversalMapLinesProps {
    lines?: LineInfo;
    styles?: LineStyles;
    selectedID?: string;
    rerenderNumber?: number;
}

function GenerateTooltipTranslate(
    from_x: number,
    from_y: number,
    to_x: number,
    to_y: number
): string {
    return `translate(${from_x <= to_x ? "0" : "-100%"},${
        from_y <= to_y ? "0" : "-100%"
    }) translate(${from_x <= to_x ? "3px" : "-3px"},${
        from_y <= to_y ? "3px" : "-3px"
    })`;
}

const UniversalMapLines: React.FC<UniversalMapLinesProps> = ({
    lines,
    styles,
    selectedID,
    rerenderNumber,
}) => {
    const [, setRenderState] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null);
    const rerender = () => {
        setRenderState((old) => old + 1);
    };
    const [HoveredId, SetHoveredId] = useState<string | undefined>(undefined);

    useLayoutEffect(() => {
        rerender();
    }, []);

    if (!lines) {
        return null;
    }

    let extentedInfo: ExtentedInfo[] = [];

    if (ref.current && ref.current.parentElement) {
        const container = ref.current.parentElement;

        lines.items.forEach((line) => {
            const srcElem = document.getElementById(line.src);
            const dstElem = document.getElementById(line.dst);
            if (!srcElem || !dstElem) return null;

            const srcPos = getOffsetRect(srcElem, container);
            const dstPos = getOffsetRect(dstElem, container);
            if (!srcPos || !dstPos) return null;

            const srcInfo = {
                id: line.src,
                elem: srcElem,
                pos: srcPos,
                center: {
                    x: srcPos.x + srcPos.width / 2,
                    y: srcPos.y + srcPos.height / 2,
                },
            };

            const dstInfo = {
                id: line.dst,
                elem: dstElem,
                pos: dstPos,
                center: {
                    x: dstPos.x + dstPos.width / 2,
                    y: dstPos.y + dstPos.height / 2,
                },
            };

            const [dist2, angle] = calculateDist2Angle(
                srcInfo.center,
                dstInfo.center
            );

            let srcAngle = angle;
            let dstAngle = angle + Math.PI;
            if (dstAngle >= 2 * Math.PI) {
                dstAngle -= 2 * Math.PI;
            }

            /*
            if (dist2 < px("15rem") * px("15rem")) {
                // rem in pixels
                dstAngle = srcAngle + Math.PI / 4;
                srcAngle += (3 * Math.PI) / 4;
            }
            */

            if (srcElem.contains(dstElem)) {
                srcAngle = Math.PI / 2 + Math.PI / 4;
                dstAngle = (3 * Math.PI) / 2 - Math.PI / 4;
            } else if (dstElem.contains(srcElem)) {
                dstAngle = Math.PI / 2 + Math.PI / 4;
                srcAngle = (3 * Math.PI) / 2 - Math.PI / 4;
            }

            if (srcElem === dstElem) {
                srcAngle = Math.PI / 36;
                dstAngle = 2 * Math.PI - Math.PI / 36;
            }

            extentedInfo.push({
                id: line.id ?? `${srcInfo.id}_${dstInfo.id}`,
                type: line.type,
                arrow: line.arrow,
                src: srcInfo,
                dst: dstInfo,
                dist2: dist2,
                srcDstAngle: angle,
                srcAngle: srcAngle,
                dstAngle: dstAngle,
                srcTooltip: line.srcTooltip,
                dstTooltip: line.dstTooltip,
            });
        });

        // collect info
        let elementsWithArrows: Record<string, ArrowInfo[]> = {};
        extentedInfo.forEach((info) => {
            // handling the source element
            if (!elementsWithArrows[info.src.id]) {
                elementsWithArrows[info.src.id] = [];
            }
            elementsWithArrows[info.src.id].push({
                type: "src",
                angle: info.srcAngle,
                extInfo: info,
            });

            // handling the destination element
            if (!elementsWithArrows[info.dst.id]) {
                elementsWithArrows[info.dst.id] = [];
            }
            elementsWithArrows[info.dst.id].push({
                type: "dst",
                angle: info.dstAngle,
                extInfo: info,
            });
        });

        for (const id in elementsWithArrows) {
            if (elementsWithArrows[id].length < 2) {
                continue;
            }

            elementsWithArrows[id].sort((a, b) => {
                if (Math.abs(a.angle - b.angle) < 0.000001) {
                    const aId =
                        a.type === "src" ? a.extInfo.dst.id : a.extInfo.src.id;
                    const theone = aId > id ? -1 : 1;
                    return a.extInfo.id > b.extInfo.id ? theone : -theone;
                }
                return a.angle - b.angle;
            });

            let maxGap = 0;
            let minGap = Number.MAX_VALUE;
            let maxGapIndex = -1;
            let arrows = elementsWithArrows[id];

            // Iterate through the array and find the largest gap
            for (let i = 1; i < arrows.length; i++) {
                const gap = arrows[i].angle - arrows[i - 1].angle;
                if (gap > maxGap) {
                    maxGap = gap;
                    maxGapIndex = i;
                }
                if (gap < minGap) {
                    minGap = gap;
                }
            }

            // Check the gap between the last and first elements, considering 2 * Math.PI period
            const gapBetweenLastAndFirst =
                2 * Math.PI - arrows[arrows.length - 1].angle + arrows[0].angle;

            if (gapBetweenLastAndFirst > maxGap) {
                maxGap = gapBetweenLastAndFirst;
                maxGapIndex = 0;
            }
            if (gapBetweenLastAndFirst < minGap) {
                minGap = gapBetweenLastAndFirst;
            }

            // Shift the array such that the largest gap is between the last and first elements
            arrows =
                maxGapIndex === 0
                    ? arrows
                    : [
                          ...arrows.slice(maxGapIndex),
                          ...arrows.slice(0, maxGapIndex),
                      ];

            if (minGap <= Math.PI / 12) {
                // need to place arrows by equal intervals
                let newGap = maxGap / 1.5;

                if (
                    2 * Math.PI - maxGap >
                    ((arrows.length - 1) * Math.PI) / 4
                ) {
                    newGap = maxGap;
                }

                let currentAngle = arrows[0].angle - (maxGap - newGap) / 2;
                let dAngle = (2 * Math.PI - newGap) / (arrows.length - 1);
                if (currentAngle < 0) {
                    currentAngle -= 2 * Math.PI;
                }

                arrows.forEach((arrow) => {
                    arrow.angle = currentAngle;
                    currentAngle += dAngle;
                    if (currentAngle > 2 * Math.PI) {
                        currentAngle -= 2 * Math.PI;
                    }
                });

                arrows.forEach((arrow) => {
                    if (arrow.type === "src") {
                        arrow.extInfo.srcAngle = arrow.angle;
                    } else {
                        arrow.extInfo.dstAngle = arrow.angle;
                    }
                });
            }
        }
    }

    const lineMarkers: { [Key: string]: React.ReactNode } = {};
    const lineTooltips: React.ReactNode[] = [];
    const linePathes: React.ReactNode[] = [];

    extentedInfo.forEach((item, index) => {
        const isSelected = selectedID === item.id;
        let style: TypedLineStyle | undefined;
        let lineStyle: LineStyle | undefined;

        style = styles?.[item.type ?? ""];
        if (style) {
            lineStyle = isSelected
                ? mergeObjects(style.line, style.lineSelected)
                : style.line;
        }

        const srcOffset = findIntersectionOffset(
            item.srcAngle,
            item.src.pos.width,
            item.src.pos.height
        );
        const dstOffset = findIntersectionOffset(
            item.dstAngle,
            item.dst.pos.width,
            item.dst.pos.height
        );
        const srcX = item.src.pos.x + srcOffset.x;
        const srcY = item.src.pos.y + srcOffset.y;
        const dstX = item.dst.pos.x + dstOffset.x;
        const dstY = item.dst.pos.y + dstOffset.y;

        const gravity = lineStyle?.gravity ?? DEFAULT_GRAVITY;

        const fillColor = lineStyle?.stroke ?? DEFAULT_STROKE;

        if (!(fillColor in lineMarkers)) {
            lineMarkers[fillColor] = (
                <marker
                    id={`arrow_${fillColor}`}
                    key={`arrow_${fillColor}`}
                    viewBox="0 0 10 10"
                    refX="5"
                    refY="5"
                    markerWidth="3"
                    markerHeight="3"
                    fill={fillColor}
                    orient="auto-start-reverse"
                >
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
            );
        }

        if (HoveredId === item.id) {
            if (item.srcTooltip) {
                lineTooltips.push(
                    <Paper
                    key={`${item.id}_srcTooltip`}
                        p="xs"
                        withBorder
                        sx={{
                            wordWrap: "normal",
                            position: "absolute",
                            left: srcX,
                            top: srcY,
                            transform: GenerateTooltipTranslate(
                                dstX,
                                dstY,
                                srcX,
                                srcY
                            ),
                        }}
                    >
                        {item.srcTooltip}
                    </Paper>
                );
            }

            if (item.dstTooltip) {
                lineTooltips.push(
                    <Paper
                        key={`${item.id}_dstTooltip`}
                        p="xs"
                        withBorder
                        sx={{
                            wordWrap: "normal",
                            position: "absolute",
                            left: dstX,
                            top: dstY,
                            transform: GenerateTooltipTranslate(
                                srcX,
                                srcY,
                                dstX,
                                dstY
                            ),
                        }}
                    >
                        {item.dstTooltip}
                    </Paper>
                );
            }
        }

        linePathes.push(
          <Fragment key={item.id}>
            <path
              id={item.id}
              className="um-line"
              d={`M${srcX} ${srcY} 
                    C ${srcX + gravity * Math.cos(item.srcAngle)} ${
                srcY + gravity * Math.sin(item.srcAngle)
              }, ${dstX + gravity * Math.cos(item.dstAngle)} ${
                dstY + gravity * Math.sin(item.dstAngle)
              }, ${dstX} ${dstY}`}
              stroke={fillColor}
              strokeWidth={lineStyle?.strokeWidth ?? DEFAULT_STROKE_WIDTH}
              strokeOpacity={lineStyle?.strokeOpacity ?? DEFAULT_STROKE_OPACITY}
              strokeLinecap="round"
              fill="none"
              style={{
                pointerEvents: "visiblePainted",
                cursor: "pointer",
              }}
              markerEnd={
                item.arrow === undefined ||
                item.arrow === "forward" ||
                item.arrow === "both"
                  ? `url(#arrow_${fillColor})`
                  : undefined
              }
              markerStart={
                item.arrow === "reverse" || item.arrow === "both"
                  ? `url(#arrow_${fillColor})`
                  : undefined
              }
              onMouseOver={() => SetHoveredId(item.id)}
              onMouseOut={() => SetHoveredId(undefined)}
            />
          </Fragment>
        );
    });

    return (
        <div
            ref={ref}
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                pointerEvents: "none",
            }}
        >
            <svg
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                }}
            >
                <defs>{Object.values(lineMarkers)}</defs>
                {linePathes}
            </svg>
            {lineTooltips}
        </div>
    );
};

export default UniversalMapLines;
