import {
    Accordion,
    Alert,
    Badge,
    Collapse,
    Container,
    Loader,
    Popover,
    ScrollArea,
    Space,
    Table,
    Text,
    Title,
    Tooltip,
    useMantineTheme,
} from "@mantine/core";
import { useAnalyzation } from "../core/hooks/analyzation";
import {
    IconAlertTriangle,
    IconCertificate,
    IconCircle,
    IconHammer,
    IconHelpCircle,
    IconMinus,
    IconPlus,
} from "@tabler/icons-react";
import { severityToColor, severityToText } from "../core/severity";
import { useDisclosure } from "@mantine/hooks";
import {
    AnalyzeCheck,
    AnalyzeGroup,
    AnalyzeValue,
    AnalyzeValueObject,
} from "../core/models/IAnalyzation";
import { modals } from "@mantine/modals";
import React from "react";

function jsxJoinLines(array: any[]) {
    return array.length > 0
        ? array.reduce((result, item) => (
              <>
                  {result}
                  <br />
                  {item}
              </>
          ))
        : null;
}

function AnalyzeItemCellLine(value: string | AnalyzeValueObject) {
    if (typeof value === "string") {
        return value;
    }
    return (
        <Tooltip withArrow multiline withinPortal maw="50%" sx={{overflowWrap: "break-word"}} label={value.hint}>
            <span>{value.name}</span>
        </Tooltip>
    );
}

export function AnalyzeItemCell({ value }: { value: AnalyzeValue }) {
    if (Array.isArray(value)) {
        return jsxJoinLines(value.map((v) => AnalyzeItemCellLine(v)));
    }
    return AnalyzeItemCellLine(value);
}

export function AnalyzeItem({
    data,
    index,
}: {
    data: AnalyzeCheck;
    index: number;
}) {
    const [severityOpened, { close: severityClose, open: severityOpen }] =
        useDisclosure(false);
    const [helpOpened, { close: helpClose, open: helpOpen }] =
        useDisclosure(false);
    const [tipsOpened, { close: tipsClose, open: tipsOpen }] =
        useDisclosure(false);

    return (
        <Accordion.Item value={index.toString()}>
            <Accordion.Control>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <div style={{ flexGrow: 1 }}>
                        <Popover withArrow shadow="sm" opened={severityOpened}>
                            <Popover.Target>
                                <IconCircle
                                    stroke="0.05rem"
                                    fill={severityToColor(data.severity)}
                                    onMouseEnter={severityOpen}
                                    onMouseLeave={severityClose}
                                />
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Text size="md">{`Severity: ${severityToText(
                                    data.severity
                                )}`}</Text>
                            </Popover.Dropdown>
                        </Popover>

                        <Text
                            display="inline"
                            sx={{ verticalAlign: "0.35rem" }}
                            ml="xs"
                            mr="xs"
                        >
                            {data.label}
                        </Text>
                        <Popover
                            width="30rem"
                            withArrow
                            shadow="sm"
                            opened={helpOpened}
                        >
                            <Popover.Target>
                                <IconHelpCircle
                                    stroke="0.1rem"
                                    onClick={(e) => { e.stopPropagation() }}
                                    onMouseEnter={helpOpen}
                                    onMouseLeave={helpClose}
                                />
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Text size="md">{data.description}</Text>
                            </Popover.Dropdown>
                        </Popover>

                        <Popover
                            width="30rem"
                            withArrow
                            shadow="md"
                            opened={tipsOpened}
                        >
                            <Popover.Target>
                                <IconHammer
                                    stroke="0.1rem"
                                    onClick={(e) => { e.stopPropagation() }}
                                    onMouseEnter={tipsOpen}
                                    onMouseLeave={tipsClose}
                                />
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Text size="md">{data.tips}</Text>
                            </Popover.Dropdown>
                        </Popover>
                    </div>
                    <div>
                        <Badge size="lg" variant="filled" ml="xs">
                            {data.data.length} ⌄
                        </Badge>
                    </div>
                </div>
            </Accordion.Control>
            <Accordion.Panel>
                <ScrollArea type="auto">
                    <Table striped miw="55rem">
                        <thead>
                            <tr>
                                {data.caption.map((caption, i) => (
                                    <th key={i}>{caption}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map((r, j) => (
                                <tr key={j}>
                                    {r.map((col, i) => (
                                        <td key={i}>
                                            <AnalyzeItemCell value={col} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ScrollArea>
            </Accordion.Panel>
        </Accordion.Item>
    );
}

export function AnalyzeGroupItem({ group }: { group: AnalyzeGroup }) {
    const theme = useMantineTheme();
    const isDark = theme.colorScheme === "dark";

    const [complianceOpened, { close: complianceClose, open: complianceOpen }] =
        useDisclosure(false);
    const [opened, { toggle }] = useDisclosure(false);

    let groupSeverityStat = new Map<number, number>();
    group.findings.forEach((e) =>
        groupSeverityStat.set(
            e.severity,
            (groupSeverityStat.get(e.severity) ?? 0) + e.data.length
        )
    );

    const severityElements = Array.from(groupSeverityStat).sort(([severityA], [severityB]) => severityB - severityA).map(
        ([severity, count]) => (
            <React.Fragment key={severity}>
                <IconCircle                    
                    stroke="0.05rem"
                    fill={severityToColor(severity)}
                />
                <Text pr="sm" sx={{display: "inline", verticalAlign: "0.35rem" }}>
                    {count}
                </Text>
            </React.Fragment>
        )
    );

    const complianceDetails = group.compliance_affected &&
        group.compliance_affected.length > 0 && (
            <ScrollArea type="auto">
                <Table horizontalSpacing="sm" withColumnBorders miw="55rem">
                    <thead>
                        <tr>
                            <th>Standard</th>
                            <th>Section</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.compliance_affected.map((info) =>
                            info.reference.map((item, i) => (
                                <tr key={(info.standard, item.item)}>
                                    {i === 0 && (
                                        <td rowSpan={info.reference.length}>
                                            {info.standard}
                                        </td>
                                    )}
                                    <td>{item.item}</td>
                                    <td>{item.description}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </ScrollArea>
        );

    return (
        <>
            <div
                style={{
                    transition: "all 0.25s",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: opened ? undefined : "0.5rem",
                    paddingBottom: opened ? "0.25rem" : "0.5rem",
                    marginBottom: opened ? undefined : "1.5rem",
                    paddingLeft: "1.5rem",
                    paddingRight: "0.5rem",
                    borderRadius: "0.25rem",
                    background: opened
                        ? undefined
                        : isDark
                        ? "#25262b"
                        : "#fff",
                    cursor: "pointer",
                    userSelect: "none"
                }}
                onClick={toggle}
            >
                <Title order={3}>
                    {group.group}{" "}
                    {group.compliance_affected &&
                        group.compliance_affected.length > 0 && (
                            <Popover
                                withArrow
                                shadow="md"
                                opened={complianceOpened}
                            >
                                <Popover.Target>
                                    <IconCertificate
                                        stroke="0.1rem"
                                        onMouseEnter={complianceOpen}
                                        onMouseLeave={complianceClose}
                                        onClick={(e) => {
                                            modals.open({
                                                title: (
                                                    <Text fw={500}>
                                                        Standards non-compliance
                                                        details for "
                                                        {group.group}"
                                                    </Text>
                                                ),
                                                size: "100vw",
                                                centered: true,
                                                children: complianceDetails,
                                            });
                                            e.stopPropagation();
                                        }}
                                    />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <>
                                        <Title order={5}>
                                            Standards non-compliance
                                        </Title>
                                        <Table
                                            horizontalSpacing="sm"
                                            withColumnBorders
                                        >
                                            <tbody>
                                                {group.compliance_affected.map(
                                                    (info) => (
                                                        <tr key={info.standard}>
                                                            <td>
                                                                {info.standard}
                                                            </td>
                                                            <td>
                                                                {info.reference
                                                                    .map(
                                                                        (r) =>
                                                                            r.item
                                                                    )
                                                                    .join(", ")}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                        <Text c="dimmed" size="0.8rem">
                                            Click the certificate icon to view
                                            details
                                        </Text>
                                    </>
                                </Popover.Dropdown>
                            </Popover>
                        )}
                    <br />
                    <Collapse in={!opened}>
                        <Text c="dimmed" size="sm">
                            {severityElements}
                        </Text>
                    </Collapse>
                </Title>
                {opened ? <IconMinus /> : <IconPlus />}
            </div>
            <Collapse in={opened}>
                <Accordion
                    variant="separated"
                    multiple={true}
                    styles={{
                        chevron: { width: 0, minWidth: 0, marginLeft: 0 },
                    }}
                    pb="lg"
                >
                    {group.findings.map((d, i) => (
                        <AnalyzeItem data={d} index={i} key={i} />
                    ))}
                </Accordion>
            </Collapse>
        </>
    );
}

export function Analyze() {
    const { loading, error, data } = useAnalyzation();

    return (
        <Container size="xl">
            <Space h="sm" />
            {loading && (
                <Loader
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                />
            )}
            {!loading && error && (
                <Alert
                    icon={<IconAlertTriangle size="1rem" />}
                    title="Cannot get analysis results"
                    color="red"
                    mt={"xs"}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    {error}
                </Alert>
            )}
            {!loading &&
                data &&
                data.data.map((group, i) => (
                    <AnalyzeGroupItem group={group} key={i} />
                ))}
        </Container>
    );
}
